import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import assets from "../Assets/assets";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [activePath, setActivePath] = useState("/");

  // Update active path when location changes
  useEffect(() => {
    setActivePath(location.pathname);
  }, [location]);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('.mobile-menu-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  // NavLink component for consistent styling
  const NavLink = ({ to, children }) => (
    <Link
      to={to}
      className={`hover:text-primary transition-colors duration-200 ${
        activePath === to ? "text-green-600 font-semibold" : "text-gray-700"
      }`}
    >
      {children}
    </Link>
  );

  return (
    <nav className="bg-white shadow-lg w-full z-50 sticky top-0">
      <div className="container mx-auto flex items-center justify-between px-6 py-4">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src={assets.logo}
            alt="Logo"
            className="h-10 w-auto object-contain"
          />
        </Link>

        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-8">
          <li>
            <NavLink to="/">Home</NavLink>
          </li>
          <li>
            <NavLink to="/pricing">Pricing</NavLink>
          </li>
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
          <li>
            <NavLink to="/contact">Contact</NavLink>
          </li>
          <li>
            <NavLink to="#">Resources</NavLink>
          </li>
        </ul>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-gray-700 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
          aria-label="Toggle menu"
        >
          {isOpen ? <FiX size={28} /> : <FiMenu size={28} />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="mobile-menu-container md:hidden">
          <ul className="absolute w-full bg-white shadow-lg flex flex-col border-t border-gray-200 p-6 gap-4">
            <li className="border-b border-gray-100">
              <NavLink 
                to="/" 
                className="block px-6 py-4 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                Home
              </NavLink>
            </li>
            <li className="border-b border-gray-100">
              <NavLink 
                to="/pricing" 
                className="block px-6 py-4 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                Pricing
              </NavLink>
            </li>
            <li className="border-b border-gray-100">
              <NavLink 
                to="/about" 
                className="block px-6 py-4 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/contact" 
                className="block px-6 py-4 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="#" 
                className="block px-6 py-4 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                Resources
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;